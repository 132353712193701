export default {
	D0000: { // TODO
		Attributes: {
			// https://roc-connect.atlassian.net/wiki/spaces/ROCW/pages/753860609/Plato#Time-Checks
			// Year: "0001",
			// Month: "0002",
			// Day: "0003",
			Hour: "0004",
			Minute: "0005",
			// Second: "0006",
			DayOfWeek: "0007",
			MinuteOfDay: "0008",
			SmartMode: "FFF1",
		},
	},
	D0003: {
		CmdIds: {
			Identify: "00",
		},
		CmdPayloads: {
			Time: "0012",
		},
	},
	D0004: {
		Attributes: {
			Groups: "groups",
		},
	},
	D0006: {
		Attributes: {
			OnOff: "0000",
			EndTime: "FFB9",
			ActionSource: "FFBA",
			DelayedAction: "FFBB",
		},
		CmdIds: {
			TurnOffDevice: "00",
			TurnOnDevice: "01",
			ToggleDevice: "02",
		},
	},
	D0008: {
		Attributes: {
			OnOff: "0000",
			RemainingTime: "0001",
			OnOffTransitionTime: "0010",
			OnLevel: "0011",
		},
		CmdIds: {
			MoveToLevel: "00",
			Move: "01",
			Step: "02",
			Stop: "03",
			MoveToLevelWithOnOff: "04",
			MoveWithOnOff: "05",
			StepWithOnOff: "06",
			Stop2: "07",
		},
	},
	D000C: {
		Attributes: {
			PresentValue: "0055",
			ApplicationType: "0100",
		},
	},
	D0019: {
		Attributes: {
			CurrentFirmwareStatus: "FF00",
			UpgradeProgessPercentage: "FF01",
		},
		CmdPayloads: {
			ScheduleUpgrade: "05",
			CancelScheduledUpgrade: "01",
		},
	},
	D0100: {
		Attributes: {
			PhysicalClosedLimit: "0000",
			MotorStepSize: "0001",
			Status: "0002",
			ClosedLimit: "0010",
			Mode: "0011",
			TerminalOrientation: "FF00",
		},
		CmdPayloads: {
			Normal: "00",
			Configure: "01",
		},
	},
	D0101: {
		Attributes: {
			LockStatus: "0000",
			ReadWriteStatus: "0003",
			ActuatorPresent: "FF02",
			DoorState: "FF03",
		},
		CmdIds: {
			SecureLock: "00",
			UnlockLock: "01",
		},
	},
	D0102: {
		Attributes: {
			WindowCoveringType: "0000",
			ConfigStatus: "0007",
			CurrentPositionLiftPercentage: "0008",
			Mode: "0017",
			ConfigurationControls: "FF07",
			EndTime: "FFB9",
			ActionSource: "FFBA",
			DelayedAction: "FFBB",
		},
		CmdIds: {
			Open: "00",
			Close: "01",
			Stop: "02",
			GoToLiftPercentage: "05",
			StepChangeLiftPercentage: "F0",
			Mode: "02",
		},
	},
	D0201: {
		// TODO
	},
	D0300: {
		Attributes: {
			CurrentHue: "0000",
			CurrentSaturation: "0001",
			CurrentX: "0003",
			CurrentY: "0004",
			ColorTemperature: "0007",
			ColorMode: "0008",
			PrimariesCount: "0010",
			PrimaryRedX: "0011",
			PrimaryRedY: "0012",
			PrimaryGreenX: "0015",
			PrimaryGreenY: "0016",
			PrimaryBlueX: "0019",
			PrimaryBlueY: "001A",
			ColorLoopActive: "4002",
			ColorCapabilities: "400A",
			ColorTempMiredPhysicalMin: "400B",
			ColorTempMiredPhysicalMax: "400C",
			ColorModeTuya: "FF00",
			BulbType: "FFF9",
			Groups: "groups",
		},
		CmdIds: {
			MoveToHue: "00",
			MoveHue: "01",
			StepHue: "02",
			MoveToSaturation: "03",
			MoveSaturation: "04",
			StepSaturation: "05",
			MoveToHueAndSaturation: "06",
			MoveToColor: "07",
			MoveColor: "08",
			StepColor: "09",
			MoveToColorTemp: "0A",
			ColorLoop: "F0",
		},
		CmdPayloads: {
			StartLoop: "00",
			StopLoop: "01",
		},
	},
	D0400: {
		Attributes: {
			Luminance: "0000",
		},
	},
	D0402: {
		Attributes: {
			Temperature: "0000",
			MinTemperatureCelsius: "0010",
			MaxTemperatureCelsius: "0011",
			StepSizeCelsius: "0012",
			MinTemperatureFahrenheit: "0020",
			MaxTemperatureFahrenheit: "0021",
			StepSizeFahrenheit: "0022",
		},
	},
	D0403: {
		Attributes: {
			MeasureValuePressure: "0000",
			MinMeasuredValue: "0001",
			MaxMeasuredValue: "0002",
			Tolerance: "0003",
		},
	},
	D0405: {
		Attributes: {
			Humidity: "0000",
		},
	},
	D0500: {
		Attributes: {
			ZoneState: "0000",
			ZoneType: "0001",
			VibrationSensitivity: "1000",
			Alarm1: "FF01",
			Alarm2: "FF02",
			Tamper: "FF03",
			LowBat: "FF04",
			Supervision: "FF05",
			ReportsRestore: "FF06",
			TroubleFailure: "FF07",
			MainsFault: "FF08",
			ExtendedStatus: "FF09",
		},
	},
	D0502: {
		CmdIds: {
			Arm: "00",
		},
		CmdPayloads: {
			Deactivated: "00,0000",
			Activated: "13,ffff",
		},
	},
	D0702: {
		Attributes: {
			OverallPowerConsumption: "0000",
			OverallPowerProduction: "0001",
			Multiplier: "0301",
			Divisor: "0302",
			InstantaneousPowerConsumption: "0400",
			ZWaveResetable: "FF00",
			ZwaveLastReset: "FF01",
			InvertPowerCapability: "FF02",
			ProducingOrConsuming: "FF03",
			FeedIn: "FF51",
			LastProductionReset: "FF52",
			NetPowerConsumption: "FF53",
		},
		CmdIds: {
			ResetZWavePowerMetering: "F0",
			InvertPower: "F1",
			ResetProduction: "F2",
		},
		CmdPayloads: {
			Consuming: "00",
			Producing: "01",
		},
	},
	DC38A: {
		Attributes: {
			Quality: "0001",
			Flip: "0002",
			Mirror: "0003",
			Motion: "0004",
			MotionSensitivity: "0005",
			SDRecording: "0006",
			PrivacyMode: "0008",
			LEDMode: "0009",
			MjpegCapability: "000A",
			RestartAvailable: "000B",
			ViewersCount: "0010",
			OSDTimestamp: "0012",
			SupportedRecordingModes: "0013",
			CurrentRecordingMode: "0014",
			SDCardStatusTuya: "0015",
			SDCardUsageTuya: "0016",
			MotionSensitivityTuya: "0026",
			NightVisionMode: "0027",
			PirSensitivityTuya: "0028",
			FloodlightSwitchTuya: "0029",
			FloodlightLevelTuya: "002A",
			MobileStreamingCapabilities: "FF00",
			WebStreamingCapabilities: "FF01",
			StreamStatus: "FF55",
		},
		CmdIds: {
			RebootCamera: "00",
			SnapshotCamera: "02",
			RTSPStream: "01",
			MJPEGStream: "02",
			HLSStream: "03",
			WebRTCStream: "04",
			SDFormatTuya: "10",
			RequestCameraOwnerCredentials: "11",
		},
	},
	DC38B: {
		Attributes: {
			CurrentPan: "0000",
			CurrentTilt: "0001",
			MaxPan: "0002",
			MinPan: "0003",
			MaxTilt: "0004",
			MinTilt: "0005",
			MovementType: "0006",
			GoHomeCapability: "0007",
			MotionTrackingTuya: "F000",
		},
		CmdIds: {
			PanAbsolute: "00",
			TiltAbsolute: "01",
			GoHome: "02",
			PanRelative: "03",
			TiltRelative: "04",
		},
	},
	DC38C: {
		Attributes: {
			Muted: "0001",
		},
	},
	DC38E: {
		Attributes: {
			KnownPersonDetected: "0000",
			StrangerDetected: "0001",
			HumanDetected: "0002",
			PetDetected: "0003",
			CarDetected: "0004",
		},
	},
	DF000: {
		// TODO
	},
	DF001: {
		// TODO
	},
	DF100: {
		Attributes: {
			NeedsWakeUp: "0000",
			// LastDeviceWakeUp: "0001"
		},
		CmdIds: {
			// GetParameter: "00", // deprecated
			// SetParameter: "01", // deprecated
			GetParameterWithSize: "02",
			SetParameterWithSize: "03",
		},
	},
	DF1F0: {
		Attributes: {
			DeviceType: "0000",
			ParentsGatewayId: "0001",
			ParentsDeviceId: "0002",
		},
		CmdIds: {
			PairSubdevices: "00",
		},
	},
	DFC00: {
		Attributes: {
			OnOff: "0001",
		},
		CmdIds: {
			TurnOffPetImmunity: "00",
			TurnOnPetImmunity: "01",
		},
	},
	DFD00: {
		Attributes: {
			PulsPerKwh: "0300",
		},
	},
	DFD01: {
		Attributes: {
			PH: "0000",
		},
	},
	DFD02: {
		Attributes: {
			OydoReductionPotential: "0000",
		},
	},
	DFD03: {
		Attributes: {
			SaltConcentration: "0000",
		},
	},
	DFD04: {
		Attributes: {
			TotalDissolvedSolids: "0000",
		},
	},
	DFD05: {
		Attributes: {
			LatestWaitingRecommendation: "0000",
		},
	},
	DFF69: {
		Attributes: {
			Temperature: "0000",
			Setpoint: "0011",
			ST_Mode: "0021",
			PresenceTime: "0022",
			ST_State: "0023",
			ST_Calibration: "0024",
			ST_Hysteresis: "0025",
			SinceInMode: "0026",
		},
	},
	DFF71: {
		Attributes: {
			NotificationString: "0000",
			NotificationKey: "0001",
		},
	},
	DFF80: {
		Attributes: {
			CurrentMode: "0000",
			CurrentSetpoint: "0001",
			AvailableModes: "0002",
			MaxHeat: "0010",
			MinHeat: "0011",
			StepSizeHeat: "0012",
			MaxCool: "0020",
			MinCool: "0021",
			StepSizeCool: "0022",
			DifferentBehavior: "FF00",
			SetpointControllable: "FF01",
			PowerMode: "FF02",
			ChildLockStatus: "FF03",
			FanSpeed: "FF21",
			ShakeStatus: "FF22",
			ShakeLevel: "FF23",
		},
		CmdIds: {
			SetMode: "00",
			SetSetPoint: "01",
			SetSetPointAndMode: "02",
		},
		CmdPayloads: {
			SetModeOff: "00",
			SetModeHeat: "01",
			SetModeCool: "02",
			SetModeAuto: "03",
			SetModeAuxiliary: "04",
			SetModeResume: "05",
			SetModeFan: "06",
			SetModeFurnace: "07",
			SetModeDry: "08",
			SetModeMoist: "09",
			SetModeAutoChangeover: "0A",
			SetModeEnergyHeat: "0B",
			SetModeEnergyCool: "0C",
			SetModeAway: "0D",
			// SetModeXXX: "0E",
			SetModeFullPower: "0F",
			SetModeDisinfection: "10",
			SetModeStandby: "11",
		},
	},
	DFF81: {
		Attributes: {
			CoLevel: "0000",
		},
	},
	DFF82: {
		Attributes: {
			MaintenanceMode: "0000",
		},
	},
	DFF83: {
		Attributes: {
			LightThreshold: "0001",
			LightDuration: "0002",
			MotionRadarRange: "0003",
			Sensitivity: "0004",
		},
		CmdIds: {
			ChangeLightThreshold: "00",
			ChangeDuration: "01",
			ChangeMotionRange: "02",
			ChangeMotionSensitivity: "03",
		},
	},
	DFF84: {
		Attributes: {
			AvailableModes: "0003",
			DeviceStateROC: "0004",
			LastErrorROC: "0005",
		},
		CmdIds: {
			ChangeMowerState: "00",
		},
		CmdPayloads: {
			Mow: "01",
			Dock: "02",
			Pause: "03",
		},
	},
	DFF85: {
		Attributes: {
			DeviceType: "0000",
			LEDStatus: "0001",
			LEDStatusWalliOutlet: "0002",
		},
		CmdPayloads: {
			LEDDisabled: "00",
			ColorByPower: "08",
			ColorByTemperature: "0A",
		},
	},
	// DFF86: {
	// 	Attributes: {
	// 		DeviceType: "0000",
	// 		Power: "0001",
	// 		CurrentSetpoint: "0002",
	// 		MaxHeat: "0010",
	// 		MinHeat: "0011",
	// 		StepHeat: "0012",
	// 		MaxCool: "0020",
	// 		MinCool: "0021",
	// 		StepCool: "0022",
	// 		ACMode: "0030"
	// 	},
	// 	CmdIds: {
	// 		SetOverlayHeatingOn: "00",
	// 		SetOverlayHeatingOff: "01",
	// 		SetOverlayConditioningOn: "02",
	// 		SetOverlayConditioningOff: "03"
	// 	},
	// 	CmdPayloads: {
	// 		Manual: "00",
	// 		TadoMode: "01",
	// 		Timer: "02",
	// 		Heat: "00",
	// 		Cool: "01"
	// 	}
	// },
	// DFF87: {
	// 	Attributes: {
	// 		CarState: "0000",
	// 		ActivePower: "0001",
	// 		TotalEnergy: "0002",
	// 		ChargedEnergy: "0003",
	// 		MaxPowerAllowed: "0004"
	// 	}
	// },
	DFF92: {
		Attributes: {
			NukiDeviceType: "0000",
			NukiDeviceState: "0001",
		},
	},
	DFF94: {
		Attributes: {
			CO2Level: "0000",
		},
	},
	DFF95: {
		Attributes: {
			VOCLevel: "0000",
		},
	},
	DFF96: {
		Attributes: {
			FoodNumber: "0000",
			FoodWeight: "0001",
			WaterUV: "0100",
		},
	},
	DFF97: {
		Attributes: {
			Power: "0000",
			PowerGo: "0001",
			VaccumMode: "0002",
			DirectionControl: "0003",
			SuctionMode: "0004",
			Charge: "0005",
		},
		VaccumModeCmdPayloads: {
			Standby: 0,
			Random: 1,
			Smart: 2,
			WallFollow: 3,
			Mop: 4,
			Spiral: 5,
			LeftSpiral: 6,
			RightSpiral: 7,
			RightBow: 8,
			LeftBow: 9,
			PartialBow: 10,
			ModeD: 11,
		},
		SuctionModeCmdPayloads: {
			Strong: 0,
			Normal: 1,
			Quiet: 2,
		},
		DirectionControlCmdPayloads: {
			Forward: 0,
			Backward: 1,
			TurnLeft: 2,
			TurnRight: 3,
			Stop: 4,
		},
	},
	/** @deprecated Use D0019 */
	DFF98: {
		Attributes: {
			CurrentFirmwareStatus: "0000",
		},
	},
	DFF99: {
		Attributes: {
			FanMode: "0000",
			HorizontalSwing: "0001",
			FanSpeed: "0002",
			VerticalSwing: "0003",
			FanDirection: "0004",
			FanModeSupport: "0010",
			FanMaxSpeed: "0012",
			FanDirectionSupport: "0014",
		},
		CmdIds: {
			Mode: "00",
			HorizontalSwing: "01",
			VerticalSwing: "02",
			FanDirection: "03",
		},
		CmdPayloads: {
			SwingOff: "00",
			SwingOn: "01",
		},
		FanDirectionCmdPayloads: {
			Forward: "00",
			Reverse: "01",
		},
	},
	DFF9A: {
		Attributes: {
			SelfCheckingResult: "0001",
			LELPercentage: "0002",
			/** @deprecated Use FF9F:0000 */
			PM25ParticleValue: "0003",
			/** @deprecated Use FF94:0000 */
			CO2Level: "0004",
		},
		CmdIds: {
			SelfCheck: "00",
		},
	},
	DFF9B: {
		Attributes: {
			StoredPasswords: "0000",
			DoorLockCapabilities: "0001",
			RequestToUnlock: "0002",
			RequestToUnlockExpiry: "0003",
			StoredOfflinePasswords: "0004",
		},
		CmdIds: {
			ListStoredPasswords: "00",
			CreatePassword: "01",
			ModifyPassword: "02",
			DeletePassword: "03",
			SyncPasswordToDoorLock: "04",
			GenerateDynamicPassword: "05",
			GenerateTempOfflinePassword: "06",
			RemoteUnlock: "07",
			ListStoredOfflinePasswords: "09",
			RenameTempOfflinePassword: "0A",
		},
		CmdPayloads: {
			ManyTimePwd: 0,
			OneTimePwd: 1,
		},
	},
	DFF9C: {
		Attributes: {
			ValveActivity: "0001",
			ValveState: "0002",
			DeviceBatteryState: "0004",
		},
	},
	DFF9D: {
		Attributes: {
			TouchDimmerSwitchLightType: "0000",
			JSQLight: "0002",
			JSQSpray: "0003",
			FSDCustomTempValue: "0004",
			LightAdjust: "0005",
			GYDDeviceMode: "0010",
			GYDSwitchPir: "0011",
			GYDPirSensitivity: "0012",
			GYDCDS: "0013",
			GYDPirDelay: "0014",
			GYDStandbyTime: "0015",
			GYDstandbyBright: "0016",
		},
	},
	DFF9E: {
		Attributes: {
			SDCardStatus: "0000",
			PowerAdapterStatus: "0001",
		},
	},
	DFF9F: {
		Attributes: {
			PM25ParticleValue: "0000",
		},
	},
	DFFAC: {
		Attributes: {
			// DeviceTypeCategory: "0000",
			// AvailablePrograms: "0001",
			// CurrentlySelectedProgram: "0002",
			RemoteControlActivationState: "0003",
			RemoteControlAllowanceState: "0004",
			// LocalControlState: "0005",
			OperationState: "0006",
			PowerState: "0007",
			CurrentPowerState: "0008",
			// PauseSupported: "0009",
			// ResumeSupported: "000A",
			// ProgramState: "000B",
			DoorState: "000C",
			AvailableModes: "000D",
			// CustomNotification: "FF00",
			LocalizedProgramName: "FF01",
		},
		CmdIds: {
			ChangePowerState: "00",
			ChangeProgram: "01",
			StartProgram: "02",
			StopProgram: "03",
			// PauseProgram: "04",
			// ResumeProgram: "05",
		},
		CmdPayloads: {
			TurnOffDevice: "00",
			TurnOnDevice: "01",
			// StandBy: "02",
		},
	},
	DFFAD: {
		Attributes: {
			TrackLocation: "0000",
			PhoneMode: "0001",
			PhoneStatus: "0002",
			LastChangeTimeStamp: "0003",
			GatewayReportAllPhonesAtHome: "0100",
		},
		CmdIds: {
			ChangePhoneMode: "00",
		},
	},
	DFFAE: {
		Attributes: {
			NotificationType: "0000",
		},
	},
	// Fake Clusters: https://roc-connect.atlassian.net/wiki/spaces/ROCW/pages/2780659713/Reports
	BC0000: {},
	BC0001: {},
	BC0002: {
		Attributes: {
			Edited: "0000",
			Deleted: "0001",
			DeviceAdded: "0002",
			DeviceRemoved: "0003",
			EditedIcon: "0004",
			EditedName: "0005",
		},
	},
	BC0003: {
		Attributes: {
			Started: "0000",
			Created: "0001",
			Edited: "0002",
			Deleted: "0003",
			Name: "1000",
			Icon: "1001",
			Actions: "1002",
		},
	},
	BC0004: {
		Attributes: {
			Timestamp: "0100",
			OperationMode: "0101",
			BackupStatus: "0102",
			BackupError: "0103",
			RestoreStatus: "0104",
			RestoreError: "0105",
		},
	},
	BC0005: {
		Attributes: {
			GeofeceToggle: "0001",
			Radius: "0002",
			Latitude: "0003",
			Longitude: "0004",
		},
	},
} as const;
