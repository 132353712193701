import Device from "./device";
import Constants from "./constants";
// types
import type { ReadonlyDeep } from "type-fest";
import type { GatewayId } from "../types/gateway";
import type { EndpointId, EpName, FullName, Cap, DeviceObj, DeviceData, Endpoint, EpDeviceType } from "../types/device";
import type { ClusterByCapAndClusterId, ClusterId } from "../types/cluster";
import type { DeviceIcons } from "../types/roc-table";
import type { PayloadBroadcastAttributeReport } from "../types/message";

class EpDevice extends Device {

	protected readonly _endpointId: EndpointId;
	protected _endpoint: ReadonlyDeep<Endpoint>;
	protected _multiEps: boolean;
	protected _epDeviceType: EpDeviceType = Constants.EpDeviceType.None;

	constructor(gatewayId: GatewayId, device: ReadonlyDeep<DeviceData>, endpointId: EndpointId, multiEps: boolean = false) {
		super(gatewayId, device);

		const endpoint = (this._invalid || endpointId === "") ? undefined : this.getEpByEpId(endpointId); // TODO: do only on read
		this._invalid ||= endpoint === undefined;
		this._endpointId = endpointId;
		this._endpoint = endpoint ?? ({} as ReadonlyDeep<Endpoint>);
		this._multiEps = multiEps;
	}

	public override clone() {
		return new EpDevice(this._gatewayId, this._device, this._endpointId, this._multiEps);
	}

	public getAsDevice(): DeviceObj {
		return new Device(this._gatewayId, this._device);
	}

	public get epId(): EndpointId {
		return this._endpointId;
	}

	public get ep(): ReadonlyDeep<Endpoint> {
		return this._endpoint;
	}

	public get epName(): EpName {
		return this._endpoint.endpointName ?? this._endpointId;
	}

	public get fullName(): FullName {
		return this._multiEps && this._epDeviceType !== Constants.EpDeviceType.Device ? `${this.name} (${this.epName})` : this.name;
	}

	public get multiEps(): boolean {
		return this._multiEps;
	}

	public set multiEps(multiEps: boolean) {
		this._multiEps = multiEps;
	}

	public get epDeviceType(): EpDeviceType {
		return this._epDeviceType;
	}

	public set epDeviceType(epDeviceType: EpDeviceType) {
		this._epDeviceType = epDeviceType;
	}

	protected override updateCluster(payload: ReadonlyDeep<PayloadBroadcastAttributeReport>) {
		super.updateCluster(payload);
		this._endpoint = this.getEpByEpId(this._endpointId) as ReadonlyDeep<Endpoint>; // TODO: do only on read
	}

	// TODO: #######################################

	public override getDeviceIcons<CAP extends Cap = Cap, CID extends ClusterId<CAP> = ClusterId<CAP>>(cap: CAP, clusterId: CID, getIconStatusFromCluster: (cluster: ClusterByCapAndClusterId<CAP, CID>) => boolean): DeviceIcons {
		const cluster = Device.getClusterFromEndpoint<CAP, CID>(this._endpoint, cap, clusterId);
		const status = cluster !== undefined && getIconStatusFromCluster(cluster);

		return Device.getDeviceIconsFromStatus(status, this._rocIdData);
	}

	public getClusterByCapAndClusterId<CAP extends Cap = Cap, CID extends ClusterId<CAP> = ClusterId<CAP>>(cap: CAP, clusterId: CID): ClusterByCapAndClusterId<CAP, CID> | undefined {
		return Device.getClusterFromEndpoint(this._endpoint, cap, clusterId);
	}

	public static getEpDeviceFromDeviceAndEpId(device: DeviceObj, endpointId: EndpointId): EpDevice {
		return new EpDevice(device.gwId, device.deviceData, endpointId);
	}

}

export default EpDevice;
