import type { RouteChanges, ActionList, EventList, ActionName, EventName, ActionData, EventData, InitData } from "../types/analytics";

abstract class Analytics {

	protected routeChanges: RouteChanges = [];
	protected actionList: ActionList = [];
	protected eventList: EventList = [];

	public emitFirstLoad(initData?: InitData | undefined): void { // eslint-disable-line @typescript-eslint/no-unused-vars
		// override
	}

	public emitRouteChange(location: string): void {
		const routeChange = this.routeChanges.find((routeChange) => ((new RegExp(routeChange.location)).test(location)));
		if (routeChange) {
			routeChange.action();
		}
	}

	public emitAction<AN extends ActionName>(actionName: AN, data: ActionData<AN>): void {
		const actionEntry = this.actionList.find((actionEntry) => (actionEntry.name === actionName));
		if (actionEntry) {
			actionEntry.action(data);
		}
	}

	public emitEvent<EN extends EventName>(eventName: EN, data?: EventData<EN>): void {
		const eventEntry = this.eventList.find((eventEntry) => (eventEntry.name === eventName));
		if (eventEntry) {
			eventEntry.event(data);
		}
	}

}

export default Analytics;
