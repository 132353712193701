import countryCurrency from "../data/countryCurrency.json";
import currencySymbol from "../data/currencySymbol.json";
import { config } from "@local/theme";
// types
import type { Country, Currency } from "../types/misc";

export const supportedCurrencies = config.supportedCurrencies;

export const getCurrencyByCountry = (country: Country | undefined) => (
	countryCurrency[country ?? ""] as Currency | undefined ?? config.defaultCurrency
);

export const getCurrencySymbol = (currency: Currency | "" | null | undefined, noDefaultFallback = false) => (
	currencySymbol[currency ?? ""] as string | undefined ?? (noDefaultFallback ? "" : currencySymbol[config.defaultCurrency] as string)
);
