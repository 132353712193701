// services
import { refreshAccessToken } from "./authenticator";

let refreshTimeoutId: number | undefined = undefined;

export const stopAccessTokenAutoRefresh = (): void => {
	window.clearTimeout(refreshTimeoutId);
	refreshTimeoutId = undefined;
};

export const restartAccessTokenAutoRefresh = (expires: number): void => {
	stopAccessTokenAutoRefresh();

	refreshTimeoutId = window.setTimeout(async () => {
		await refreshAccessToken(true);
	}, Math.max((expires - 5 * 60) * 1000, 0));
};
