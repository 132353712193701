import RocTable from "./roc-table";
import i18n from "./i18n";
import Constants from "./constants";
import ClusterConstants from "./cluster-constants";
import { Storage, StorageKeys } from "./storage";
// types
import type { ReadonlyDeep } from "type-fest";
import type { GatewayId } from "../types/gateway";
import type {
	DeviceId, DeviceName, EndpointId, Cap, PowerLevelStatus, ReachableStatus, HideActionButton,
	StoredDevice, DeviceObj, DeviceData, EndpointsData, Attributes, Endpoints, Endpoint,
} from "../types/device";
import type { Clusters, Cluster, ClusterByCapAndClusterId, ClusterId } from "../types/cluster";
import type { DeviceIcons, RocIdData, StaticEndpointsData, StaticClusters, RocId } from "../types/roc-table";
import type { PayloadBroadcastDeviceReport, PayloadBroadcastAttributeReport, ChildDocuments } from "../types/message";
import type { SortOrder } from "../types/misc";

class Device {

	protected _invalid: boolean;
	protected readonly _gatewayId: GatewayId;
	protected _device: DeviceData;
	protected _rocIdData: RocIdData;
	protected _endpoints: Endpoints;

	constructor(gatewayId: GatewayId, device: ReadonlyDeep<DeviceData>) {
		this._invalid = gatewayId === "" || device.id === "";
		this._gatewayId = gatewayId;
		this._device = device as DeviceData;
		this._rocIdData = RocTable.getRocIdData(this._device.rocId); // TODO: do only on read
		this._endpoints = Device.#getPreparedEndpoints(this._device.endpoints, this._rocIdData.staticEndpointData); // TODO: do only on read
	}

	public toStorage(): StoredDevice {
		return [this._gatewayId, this._device] as const;
	}

	public clone(): DeviceObj {
		return new Device(this._gatewayId, this._device);
	}

	public get invalid(): boolean {
		return this._invalid;
	}

	public get gwId(): GatewayId {
		return this._gatewayId;
	}

	public get deviceData(): ReadonlyDeep<DeviceData> {
		return this._device;
	}

	public get srcGw(): GatewayId {
		return this._device.srcGw;
	}

	public get id(): DeviceId {
		return this._device.id;
	}

	public get name(): DeviceName {
		return this._device.name ?? this._device.id;
	}

	public get rocId(): RocId {
		return this._device.rocId;
	}

	public get reachableStatus(): ReachableStatus {
		return this._device[Constants.Device.Property.ReachableStatus] ?? this._device.status;
	}

	public get batteryStatus(): PowerLevelStatus | undefined {
		return this._device[Constants.Device.Property.PowerLevelStatus] ?? this._device.power;
	}

	public get hideActionButton(): HideActionButton {
		return this._device[Constants.Device.Property.HideActionButton] ?? false;
	}

	public get eps(): ReadonlyDeep<Endpoints> {
		return this._endpoints;
	}

	public get categoryName(): string {
		return i18n.t([`deviceCategories.${this._rocIdData.category}`, "deviceCategories.dc_default_text"] as const);
	}

	public get typeName(): string {
		return i18n.t([`deviceTypes.${this._rocIdData.type}`, "deviceTypes.dt_default_text"] as const);
	}

	public get stringOverrideKey(): string | undefined {
		return this.getRocIdData("stringOverrideKey");
	}

	public getAttribute<ATTR extends keyof Attributes = keyof Attributes>(attributeProp: ATTR): Attributes[ATTR] {
		return this._device.attributes[attributeProp];
	}

	public getRocIdData<RPROP extends keyof RocIdData = keyof RocIdData>(rocIdDataProp: RPROP): RocIdData[RPROP] {
		return this._rocIdData[rocIdDataProp];
	}

	public getEpByEpId(endpointId: EndpointId, allowDefaultFallback: boolean = false): ReadonlyDeep<Endpoint> | undefined {
		return this._endpoints.find((endpoint) => (endpoint.endpoint === endpointId)) ?? (allowDefaultFallback ? this._endpoints[0] : undefined);
	}

	public updateDeviceDataFull(payload: ReadonlyDeep<PayloadBroadcastDeviceReport>): void {
		this._device = payload.data as DeviceData;
		this._rocIdData = RocTable.getRocIdData(this._device.rocId); // TODO: do only on read
		this._endpoints = Device.#getPreparedEndpoints(this._device.endpoints, this._rocIdData.staticEndpointData); // TODO: do only on read
	}

	public updateDeviceData(payload: ReadonlyDeep<PayloadBroadcastAttributeReport>): void {
		if (payload.endpoint === "00" && payload.cluster_id === "0000") {
			this.updateDevice(payload);
		} else if (payload.endpoint === "00" && payload.cluster_id === "BC0001") {
			this.updateDeviceEndpoint(payload);
		} else if (payload.cluster_id === "BC0002") {
			this.updateDeviceEndpointRoom(payload);
		} else {
			this.updateCluster(payload);
		}
	}

	protected updateDevice(payload: ReadonlyDeep<PayloadBroadcastAttributeReport>) {
		Device.updateObjectWithChildDocuments(this._device, payload._childDocuments_);
		this._rocIdData = RocTable.getRocIdData(this._device.rocId); // TODO: do only on read
	}

	protected updateDeviceEndpoint(payload: ReadonlyDeep<PayloadBroadcastAttributeReport>) {
		Device.updateEndpointObjectWithChildDocuments(this._device.endpoints, payload._childDocuments_);
		this._endpoints = Device.#getPreparedEndpoints(this._device.endpoints, this._rocIdData.staticEndpointData); // TODO: do only on read
	}

	protected updateDeviceEndpointRoom(payload: ReadonlyDeep<PayloadBroadcastAttributeReport>) {
		Device.updateEndpointRoomObjectWithChildDocuments(this._device.endpoints, payload);
		this._endpoints = Device.#getPreparedEndpoints(this._device.endpoints, this._rocIdData.staticEndpointData); // TODO: do only on read
	}

	protected updateCluster(payload: ReadonlyDeep<PayloadBroadcastAttributeReport>) {
		const endpoint = this._device.endpoints.find((endpoint) => (endpoint.endpoint === payload.endpoint));
		if (endpoint) {
			const clusters = endpoint[payload.caps ?? Constants.Caps.Incaps];
			if (clusters) {
				const cluster = Device.getClusterByClusterId(clusters, payload.cluster_id);
				if (cluster) {
					Device.updateObjectWithChildDocuments(cluster, payload._childDocuments_);
				}
			}
		}
		this._endpoints = Device.#getPreparedEndpoints(this._device.endpoints, this._rocIdData.staticEndpointData); // TODO: do only on read
	}

	static #getPreparedEndpoints(endpoints: EndpointsData, staticEndpointData: StaticEndpointsData | undefined): Endpoints {
		const endpointsData = structuredClone(endpoints);
		const preparedEndpoints = (staticEndpointData === undefined) ? endpointsData : Device.#mapEndpoints(staticEndpointData, endpointsData);

		for (const endpoint of preparedEndpoints) {
			for (const cap of Object.values(Constants.Caps)) {
				const clusters = endpoint[cap];
				if (Array.isArray(clusters)) {
					clusters.sort(Device.#sortClusters); // TODO: .toSorted()
				} else {
					endpoint[cap] = [];
				}
			}
		}

		return preparedEndpoints as Endpoints;
	}

	static #mapEndpoints(staticEndpoints: StaticEndpointsData, endpoints: EndpointsData): Endpoints {
		const mergedEndpoints = structuredClone(staticEndpoints) as Endpoints;
		for (const endpoint of endpoints) {
			const endpointIndex = mergedEndpoints.findIndex((mergedEndpoint) => (mergedEndpoint.endpoint === endpoint.endpoint));
			if (endpointIndex === -1) {
				mergedEndpoints.push(endpoint as Endpoint);
			} else {
				const mergedEndpoint = mergedEndpoints[endpointIndex];

				for (const endpointKey of Object.keys(endpoint)) {
					if (Object.values(Constants.Caps).includes(endpointKey)) {
						const clusters = endpoint[endpointKey] ?? [];
						mergedEndpoint[endpointKey] = Array.isArray(mergedEndpoint[endpointKey]) ? Device.#mapClusters(mergedEndpoint[endpointKey], clusters) : clusters;
					} else {
						mergedEndpoint[endpointKey] = endpoint[endpointKey];
					}
				}
			}
		}
		return mergedEndpoints;
	}

	static #mapClusters(staticClusters: StaticClusters, clusters: Clusters): Clusters {
		const mergedClusters = structuredClone(staticClusters) as Clusters;
		for (const cluster of clusters) {
			const clusterIndex = mergedClusters.findIndex((mergedCluster) => (mergedCluster.cluster_id === cluster.cluster_id));
			if (clusterIndex === -1) {
				mergedClusters.push(cluster);
			} else {
				const mergedCluster = mergedClusters[clusterIndex];
				for (const clusterKey of Object.keys(cluster)) {
					const value = cluster[clusterKey];
					if (!Object.hasOwn(mergedCluster, clusterKey) || value !== null) {
						mergedCluster[clusterKey] = value;
					}
				}
			}
		}
		return mergedClusters;
	}

	static #sortClusters(aCluster: Cluster, bCluster: Cluster): SortOrder {
		if (aCluster.cluster_id > bCluster.cluster_id) return 1;
		if (aCluster.cluster_id < bCluster.cluster_id) return -1;
		return 0;
	}

	// TODO: #######################################

	public getDeviceIcons<CAP extends Cap = Cap, CID extends ClusterId<CAP> = ClusterId<CAP>>(cap: CAP, clusterId: CID, getIconStatusFromCluster: (cluster: ClusterByCapAndClusterId<CAP, CID>) => boolean): DeviceIcons {
		const status = this._endpoints
			.map((endpoint) => (Device.getClusterFromEndpoint<CAP, CID>(endpoint, cap, clusterId)))
			.some((cluster) => (cluster !== undefined && getIconStatusFromCluster(cluster)));

		return Device.getDeviceIconsFromStatus(status, this._rocIdData);
	}

	public hasEpWithClusterId(clusterId: ClusterId): boolean {
		return this._endpoints.some((endpoint) => (Device.hasEndpointClusterId(endpoint, clusterId)));
	}

	public getClusterByEpIdAndCapAndClusterId<CAP extends Cap = Cap, CID extends ClusterId<CAP> = ClusterId<CAP>>(endpointId: EndpointId, cap: CAP, clusterId: CID): ClusterByCapAndClusterId<CAP, CID> | undefined {
		const endpoint = this._endpoints.find((endpoint) => (endpoint.endpoint === endpointId));
		return endpoint ? Device.getClusterFromEndpoint(endpoint, cap, clusterId) : undefined;
	}

	// #####

	protected static getClusterByClusterId<CID extends ClusterId = ClusterId>(clusters: ReadonlyDeep<Clusters>, clusterId: CID): ClusterByCapAndClusterId<Cap, CID> | undefined {
		return clusters.find((cluster) => (cluster.cluster_id === clusterId));
	}

	protected static getClusterFromEndpoint<CAP extends Cap = Cap, CID extends ClusterId<CAP> = ClusterId<CAP>>(endpoint: ReadonlyDeep<Endpoint>, cap: CAP, clusterId: CID): ClusterByCapAndClusterId<CAP, CID> | undefined {
		return Device.getClusterByClusterId(endpoint[cap], clusterId);
	}

	protected static hasEndpointCapClusterId<CAP extends Cap = Cap, CID extends ClusterId<CAP> = ClusterId<CAP>>(endpoint: ReadonlyDeep<Endpoint>, cap: CAP, clusterId: CID): boolean {
		return endpoint[cap].some((cluster) => (cluster.cluster_id === clusterId));
	}

	protected static hasEndpointClusterId(endpoint: ReadonlyDeep<Endpoint>, clusterId: ClusterId): boolean {
		return Object.values(Constants.Caps).some((cap) => (
			Device.hasEndpointCapClusterId(endpoint, cap, clusterId)
		));
	}

	protected static updateObjectWithChildDocuments(object: object, childDocuments: ReadonlyDeep<ChildDocuments>) {
		for (const childDocument of childDocuments) {
			object[childDocument.id] = childDocument[childDocument.val];
		}
	}

	protected static updateEndpointObjectWithChildDocuments(endpoints: EndpointsData, childDocuments: ReadonlyDeep<ChildDocuments>) {
		for (const childDocument of childDocuments) {
			const endpoint = endpoints.find((endpoint) => (endpoint.endpoint === childDocument.id));
			if (endpoint) {
				endpoint.endpointName = childDocument[childDocument.val];
			}
		}
	}

	protected static updateEndpointRoomObjectWithChildDocuments(endpoints: EndpointsData, payload: ReadonlyDeep<PayloadBroadcastAttributeReport>) {
		for (const childDocument of payload._childDocuments_) {
			const endpoint = endpoints.find((endpoint) => (endpoint.endpoint === payload.endpoint));
			const storedList = Storage.get(StorageKeys.favoriteRoomDevicesOrder, {GWID: payload.gatewayId, ROOM_ID: childDocument[childDocument.val]}, {GWID: payload.srcGw, ROOM_ID: childDocument[childDocument.val]});
			if (endpoint && childDocument.id === ClusterConstants.BC0002.Attributes.DeviceAdded) {
				endpoint.room_id = childDocument[childDocument.val];
				if (storedList !== undefined && !storedList.includes(payload.deviceId)) {
					const favoriteRoomDevicesOrder = [...storedList, payload.deviceId];
					Storage.set(StorageKeys.favoriteRoomDevicesOrder, favoriteRoomDevicesOrder, {GWID: payload.gatewayId, ROOM_ID: childDocument[childDocument.val]});
				}
			} else if (endpoint && childDocument.id === ClusterConstants.BC0002.Attributes.DeviceRemoved) {
				endpoint.room_id = undefined;
				if (storedList !== undefined) {
					const favoriteRoomDevicesOrder = storedList.filter((deviceId) => (deviceId !== payload.deviceId));
					Storage.set(StorageKeys.favoriteRoomDevicesOrder, favoriteRoomDevicesOrder, {GWID: payload.gatewayId, ROOM_ID: childDocument[childDocument.val]});
				}
			}
		}
	}

	protected static getDeviceIconsFromStatus(status: boolean, rocIdData: RocIdData): DeviceIcons {
		if (status) {
			if (rocIdData.icon_blink) {
				return [rocIdData.icon_a, rocIdData.icon_b] as const;
			} else {
				return [rocIdData.icon_a] as const;
			}
		} else {
			return [rocIdData.icon_b] as const;
		}
	}

}

export default Device;
