import { Capacitor } from "@capacitor/core";
import { FirebaseCrashlytics } from "@capacitor-firebase/crashlytics";
import { fromError } from "stacktrace-js";
// services
import Constants from "./constants";

const isFirebaseCrashlyticsEnabled = async () => {
	if (Capacitor.getPlatform() === Constants.Platform.iOS) {
		const { enabled } = await FirebaseCrashlytics.isEnabled();
		return enabled;
	} else {
		return false;
	}
};

export const enableCrashlytics = async (): Promise<void> => {
	const enabled = await isFirebaseCrashlyticsEnabled();
	if (!enabled) {
		await FirebaseCrashlytics.setEnabled({ enabled: true });
	}
};

export const trackFirebaseError = async (error: Error): Promise<void> => {
	const stacktrace = await fromError(error);
	await FirebaseCrashlytics.recordException({
		message: error.message,
		stacktrace: stacktrace,
	});
};
